<template>
  <div class="wrapper">
    <ctheader></ctheader>
    <div
      class="market-detail-product-copy"
      v-loading="loading"
      :element-loading-text="loading_text"
    >
      <div class="content1">
        <div class="left">
          <div class="top">
            <div>采集档口</div>
            <div>
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
            </div>
          </div>
          <el-select
            v-model="market_id"
            filterable
            placeholder="请选择市场"
            size="small"
            style="width: 100%; margin-top: 20px"
            @change="leftMarketChange"
          >
            <el-option
              v-for="item in marketList"
              :key="item.id"
              :label="item.market_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="orgz_id"
            filterable
            placeholder="请选择档口"
            size="small"
            style="width: 100%; margin-top: 20px"
            @change="leftChange"
          >
            <el-option
              v-for="item in orgzList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div class="cate-content">
            <div>{{ orgz_name ? orgz_name : "档口名称" }}</div>
            <el-checkbox-group v-model="tag_ids" @change="handleCheckedTag">
              <el-checkbox v-for="tag in tagList" :label="tag.id" :key="tag.id">
                {{ tag.name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <div>
          <img src="http://op.chuansmart.com/storage/img/change.png" />
        </div>
        <div class="right">
          <div class="top">
            <div>采集档口</div>
            <div>
              <el-button type="primary" size="small" @click="save"
                >保存</el-button
              >
            </div>
          </div>
          <el-select
            v-model="orgz_ids"
            filterable
            multiple
            placeholder="请选择"
            size="small"
            style="width: 100%; margin-top: 20px"
            class="right-select"
            @change="rightChange"
          >
            <el-option
              v-for="item in orgzList1"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div class="cate-content">
            <div v-for="(item, index) in orgz_names" :key="index">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <el-dialog :visible.sync="jdt" width="80%">
      <el-progress
        :percentage="percentage"
        color="#409eff"
        class="jdt_nr"
        v-show="jdt"
      ></el-progress>
    </el-dialog> -->
  </div>
</template>
<script>
import ctheader from "../../components/ctheader";
import { apiPost } from "@/modules/utils";
import APIs from "@/modules/APIs";
const actions = mapActions(["setAlert"]);
import { mapActions } from "vuex";

export default {
  name: "",
  components: { ctheader },
  mixins: [],
  props: {},
  data() {
    return {
      isIndeterminate: false,
      checkAll: false,
      orgz_id: "",
      orgzList: [],
      marketList: [],
      hq_code: "",
      market_id: "",
      options: [],
      orgz_name: "",
      orgz_ids: [],
      tagList: [],
      tag_ids: [],
      orgzList1: [],
      orgz_names: [],
      jdt: false, //进度条隐藏
      percentage: 0, //进度条值
      timeStart: "", //进度条计时
      loading: false,
      loading_text: "",
      oldMarketId: "",
      market_hqcode: "",
    };
  },
  computed: {},

  mounted() {
    console.log(this.$route.query);
    this.hq_code = this.$route.query.hq_code;
    this.market_id = parseInt(this.$route.query.id);
    this.oldMarketId = parseInt(this.$route.query.id);
    this.getOrgzList();
    this.getMarketList();
  },
  methods: {
    ...actions,

    //切换市场
    leftMarketChange(val) {
      this.market_id = val;
      this.market_hqcode = this.marketList.filter(
        (item) => item.id == this.market_id
      )[0].hq_code;
      this.tagList = [];
      this.orgz_name = "";
      this.orgz_id = "";
      this.getOrgzList();
    },

    // 市场列表
    getMarketList() {
      let $this = this;
      const params = {
        action: "market.list",
        hq_code: this.hq_code,
        start: 0,
        length: 9999,
      };
      apiPost({
        url: APIs.COMPANY,
        data: params,
        success(res) {
          if (res.code === "0") {
            $this.marketList = res.data;
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },

    //档口列表
    getOrgzList(val) {
      let $this = this;
      const params = {
        action: "orgz.list",
        market_id: val ? val : this.market_id,
        hq_code: this.hq_code,
        start: 0,
        length: 9999,
      };
      apiPost({
        url: APIs.COMPANY,
        data: params,
        success(res) {
          if (res.code === "0") {
            val ? ($this.orgzList1 = res.data) : ($this.orgzList = res.data);
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    //前台分类
    getTagList() {
      let $this = this;
      const params = {
        action: "tag.list",
        market_id: this.market_id,
        orgz_id: this.orgz_id,
        hq_code: this.market_hqcode ? this.market_hqcode : this.hq_code,
        start: 0,
        length: 9999,
      };
      apiPost({
        url: APIs.COMPANY,
        data: params,
        success(res) {
          if (res.code === "0") {
            $this.tagList = res.data;
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    handleCheckedTag(val) {
      console.log(val);
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.tagList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.tagList.length;
    },
    handleCheckAllChange(val) {
      console.log(val);
      this.tag_ids = val ? this.tagList.map((item) => item.id) : [];
      console.log(this.tag_ids);

      this.isIndeterminate = false;
    },
    leftChange(val) {
      console.log(val, "111");

      this.orgz_name =
        this.orgzList.filter((c) => c.id == val).length > 0
          ? this.orgzList.filter((c) => c.id == val)[0].name
          : "";
      this.orgzList1 = this.orgzList.filter((c) => c.id != val);
      this.getTagList();
      this.orgz_ids = [];
      this.orgz_names = [];
      this.tag_ids = [];
      this.checkAll = false;
      this.isIndeterminate = false;
      this.getOrgzList(this.oldMarketId);
    },
    rightChange() {
      console.log(this.orgz_ids);
      this.orgz_names = this.orgzList1
        .filter((c) => this.orgz_ids.includes(c.id))
        .map((item) => item.name);
      console.log(this.orgz_names);
    },
    save() {
      if (this.tag_ids.length == 0 || this.orgz_ids.length == 0) {
        this.setAlert({ msg: "请选择需要同步的信息" });
        return false;
      }
      this.loading = true;
      this.percentage = 0;
      this.loading_text = "正在同步中";

      var timeStart = setInterval(() => {
        if (this.percentage < 79) {
          this.percentage = this.percentage + 20;
          this.loading_text = "正在同步中" + this.percentage + "%";
        } else {
          this.percentage = 99;
          this.loading_text = "正在同步中" + 99 + "%";
        }
      }, 1000);
      var $this = this;
      const params = {
        action: "orgz.product.copy",
        market_id: $this.market_id,
        orgz_id: $this.orgz_id,
        orgz_ids: JSON.stringify($this.orgz_ids),
        cate_ids: JSON.stringify($this.tag_ids),
      };
      apiPost({
        url: APIs.COMPANY,
        data: params,
        success(res) {
          console.log(res);
          if (res.code === "0") {
            $this.loading = false;
            clearInterval(timeStart);
            $this.$message({
              showClose: true,
              duration: 0,
              message:
                "成功" +
                res.data.success +
                "条；" +
                "失败" +
                res.data.fail +
                "条",
              type: "success",
            });
            $this.orgz_ids = [];
            $this.orgz_id = "";
            $this.tag_ids = [];
            $this.orgz_names = [];
            $this.tagList = [];
            $this.checkAll = false;
            $this.orgz_name = "";
          } else {
            $this.loading = false;
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
      setTimeout(() => {
        // this.percentage = 100;
        // this.jdt = false;
        // this.loading = false;
        clearInterval(timeStart);
      }, 5000);
    },
  },
};
</script>
<style lang="less" scoped>
.market-detail-product-copy {
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 0 10px 0 #e0e3e8;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4c4d55;
  .content1 {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    .left {
      width: 46%;
      .top {
        display: flex;
        justify-content: space-between;
        height: 30px;
      }

      .cate-content {
        margin-top: 20px;
        background: #fbfcff;
        border-radius: 4px;
        min-height: 485px;
        padding: 16px;
      }
    }
    img {
      width: 40px;
      height: 40px;
    }
    .right {
      width: 46%;
      .top {
        display: flex;
        justify-content: space-between;
        height: 30px;
        align-items: center;
      }

      .cate-content {
        margin-top: 20px;
        background: #fbfcff;
        border-radius: 4px;
        min-height: 485px;
        padding: 16px;
        div {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
<!-- <style>
.right-select .el-select__input :focus {
  border: none !important;
}
</style> -->
<style lang="less">
.market-detail-product-copy {
  .el-select__input {
    border: none !important;
    margin-left: 0 !important;
  }
  .cate-content {
    .el-checkbox {
      display: block;
      margin-top: 10px;
    }
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #257873;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #257873;
    border-color: #257873;
  }
  .el-checkbox__input :hover {
    border-color: #257873;
  }
  .el-checkbox__input :hover {
    border-color: #257873;
  }
  .el-loading-spinner .path {
    stroke: #257873;
  }
  .el-loading-spinner .el-loading-text {
    color: #257873;
  }
}
</style>
